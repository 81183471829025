.about-page {
    padding: 2em 5em;
}

.about-page > .info {
    margin-bottom: 2em;
    font-size: larger;
}

.about-sites {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 1fr;
    grid-gap: 5em;
}

.about-site {
}

.about-site img {
    border: 1px solid white; 
    height: 100%;
    width: 100%;
    background-color: #ccc;
    position: relative;
    object-fit: contain;
}
