
header {
    grid-area: header;

    display: grid;
    grid-template-columns: 1fr auto 1fr; 
    grid-template-areas:
        'logo-left title logo-right';

    color: #CCC;
    background: linear-gradient(to top, #07111d, #152235);
    
    border-bottom: 1px solid cyan; 
    text-shadow: 1px 1px 1px #000;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.5);

    padding: 10px 15px;
    z-index: 11;
}

header h1 {
    margin: 0;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
}

header .center {
    grid-area: title;
    text-align: center;
}

header .logo-left {
    grid-area: logo-left;
    place-self: center left;
    height: 150px;
    top: -10px;
    z-index: 100;
    position: absolute;
}

header .logo-right {
    grid-area: logo-right;
    place-self: center end;
    height: 80px;
}

@media (max-width: 767px) {
    header h1 { font-size: 1em; }
    header .logo-left  { height: 95px; top: -5px; } 
    header .logo-right { height: 45px; } 
}


@media (max-width: 200px) {
    header {
        grid-template-columns: 0px 1fr 0px;
    }
    header h1 { font-size: 1em; }
    header .logo-left  { display: none; }
    header .logo-right { display: none; }
}

