
.map-page {
    padding: 2em 100px 1em 100px;
    height: 100%;
    overflow-y: auto;

    display: grid;
    grid-gap: 10px 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "map-site-photo"
        "map-site-plans";

}

.map-site-plans {
    grid-area: map-site-plans;
    overflow-y: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "map-site-overview map-site-plan";

    grid-gap: 10px 10px;
    align-content: start;
}

.map-image {
    width: 100%;
    height: 100%; 
    object-fit: contain; 
}

.map-image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.map-site-photo, .map-site-overview, .map-site-plan {
    grid-area: map-site-photo;
    overflow-y: hidden;
}

.map-site-plan {
    grid-area: map-site-plan;
}

.map-site-overview {
    grid-area: map-site-overview;
}

.map-image-ratio {
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    z-image: 100;
}

@media (max-width: 1024px) {
    .map-page {
        padding: 2em 50px 1em 50px;
    }
}

@media (max-width: 800px) {
    .map-page {
        padding: 10px;
    }
}
