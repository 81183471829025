
.weather-page {
    height: 100%;
    padding: 2em 100px 1em 100px;
    overflow-y: auto;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
        "weather-header"
        "weather-cards"
        "weather-plots";
    grid-gap: 10px 10px;
}

.weather-header  {
    grid-area: weather-header;
    border-bottom: 2px solid slategrey;
    text-align: center; 
    margin: 0;
    border: 1px solid red;
}

.weather-cards {
    grid-area: weather-cards;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-auto-rows: auto;
    grid-gap: 20px;
    margin: 10px 0;
    color: white;
}

.north-wind { 
    background-color: red !important;
    fill: red;
}

.weather-card {
    line-height: 1;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #394263;
    border-radius: 5px;

    display: grid;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    grid-template-areas:
        "card-title card-title"
        "card-value card-stats";
}

.card-title {
    grid-area: card-title;
    font-size: 2vw; 
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-value {
    grid-area: card-value;
    font-size: 3vw; 
    height: 100%;
    vertical-align: top;
}

.card-stats {
    grid-area: card-stats;
    height: 100%;
    display: grid;
    line-height: 1;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto; 
    grid-column-gap: 0.5em; 
    grid-row-gap: 0.25em;
    align-items: start;
}   

.card-stats > div {
    font-size: 1.1vmax; 
    white-space: nowrap;
}

.card-stats-compass {
    grid-area: card-stats;
    font-size: 2vw; 
}   

.weather-plots {
    grid-area: weather-plots;
    overflow-y: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    grid-template-areas:
        "temperature-plot windrose-plot"
         "winddir-plot windrose-plot"
         "windspeed-plot windrose-plot"
}

.windrose-plot {
    grid-area: windrose-plot;
}

.windline-plot {
    grid-area: windline-plot;
}

.winddir-plot {
    grid-area: winddir-plot;
}

.plot-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.temperature-plot {
    grid-area: temperature-plot;
}

.windspeed-plot {
    grid-area: windspeed-plot;
}

.pressure-plot {
    grid-area: pressure-plot;
}

.axis-grid line {
    stroke: #777; 
    stroke-width: 1px; 
}

@media (max-width: 1024px) {
    div.weather-page {
        padding: 2em 50px 1em 50px;
    }
}

@media (max-width: 800px) { 
    div.weather-page {
        padding: 10px;
    }

    .weather-cards {
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: auto; 
        grid-gap: 10px;
    }
 
    .weather-card {
        grid-template-columns: 1fr auto;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "card-title card-value"
            "card-stats card-stats";
    }

    .card-stats {
        grid-template-columns: repeat(6,min-content);
    }   
    
    .card-value {
        font-size: 5vmin; 
    }

    .card-title {
        font-size: 3.5vmin; 
    }

    .card-stats > div {
        font-size: 2vmin; 
    }

}

@media (min-aspect-ratio: 16/10) and (max-width: 800px) {
    .weather-page {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
            "weather-header weather-header"
            "weather-cards weather-plots";
    }
 
    .weather-cards {
        grid-template-columns: 1fr; 
        grid-template-rows: auto; 
        grid-gap: 10px;
    }
 
    .weather-card {
        grid-template-columns: 1fr auto;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "card-title card-value"
            "card-stats card-stats";
    }

}
