.winddial {
    fill: none;
    stroke: #999;
    stroke-width: 3px;
}

.winddial-face {
    fill: rgb(126,169,255);
    stroke: rgb(200,135,180);
    stroke-width: 0;
}

.winddial-marker {
    //fill: rgb(217,237,255);
    fill: orangered; 
    stroke: rgb(173,189,204);
    stroke-width: 0px;
}

.winddial-label { 
    fill: #ccc;
    font-size: 150px;
}
