footer {
    grid-area: footer;

    background-color: #ccc;
    color: #152235;
    font-size: smaller;
    text-align: center;
    padding: 0.25em;
    font-family: 'Roboto Condensed', sans-serif;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas:
        "footer-status footer-attribution footer-release"
}

.footer-status {
    grid-area: footer-status;
    text-align: left;
    padding-left: 1em;
    text-transform: capitalize;
}

.footer-attribution {
    grid-area: footer-attribution;
    text-align: center;
}

.footer-release {
    grid-area: footer-release;
    text-align: right;
    padding-right: 1em;
}

@media (max-width: 767px) {
    .footer-attribution {
        font-size: xx-small;
    }
}
