.home-page {
    display: none;
    padding: 2em; 
    height: 100%;
    overflow-y: auto;

    display: grid;
    grid-gap: 20px 10px; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "home-camera-tile home-time-tile"
        "home-weather-tile home-weather-tile";

    align-content: start;

    /*font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
}

.home-tile {
    /*border: 1px solid blue;
    background: #394263; 
    border-radius: 5px;*/
}

.home-time-tile {
    padding: 1em 0 0.5em 0;

    grid-area: home-time-tile;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        "clocks-tile"
        "home-time-date";
}

.home-time-date {
    grid-area: home-time-date;
    text-align: center;
    padding-top: 1vmax;
    font-size: 1.75vmax;
}

.clocks-tile {
    grid-area: clocks-tile;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
        "clock-station clock-utc";
}

.clock-tile {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
}

.clock-tile-title, .clock-tile-time {
    text-align: center;
    font-size: 2vmax;
}

.clock-tile-title {
    font-size: 2vmax;
}

#clock-station {
    grid-area: clock-station;
}

#clock-utc {
    grid-area: clock-utc;
}

.home-weather-tile {
    grid-area: home-weather-tile;
    
    display: grid;
    grid-gap: 20px 20px; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: auto 1fr; 
    grid-template-areas:
        "home-weather-tile-title home-weather-tile-title home-weather-tile-title"
        "home-weather-tile-temps home-weather-tile-wind home-weather-tile-altitude";
}

.home-weather-tile-temps, 
.home-weather-tile-wind, 
.home-weather-tile-altitude {

    border: 1px solid #07111d;
    border-radius: 20px; 
    //background-color: #07111d;

}

.home-weather-tile-temps {
    grid-area: home-weather-tile-temps;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "stat-temperature stat-temperature stat-temperature"
        "stat-temp-min stat-temp-max stat-windchill";

    justify-items: center;
    align-items: center;
    font-size: 5vmax; 
}

.stat-temperature {
    grid-area: stat-temperature;
}

.stat-temp-min {
    grid-area: stat-temp-min;
}

.stat-temp-max {
    grid-area: stat-temp-max;
}

.stat-windchill {
    grid-area: stat-windchill;
}

.home-weather-tile-title {
    grid-area: home-weather-tile-title;
    justify-self: center;
    font-size: 3vmax; 
    display: none;
}

.home-weather-tile-wind {
    grid-area: home-weather-tile-wind;
    justify-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.home-weather-tile-altitude {
    grid-area: home-weather-tile-altitude;
    white-space: nowrap;
    justify-items: center;
    align-items: center;

    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "stat-elevation stat-pressure"
        "stat-altitude stat-altitude"
        "stat-altimeter stat-altimeter";
        
}

.stat-elevation {
    grid-area: stat-elevation;
    align-self: end;
}

.stat-pressure {
    grid-area: stat-pressure;
    align-self: end;
}

.stat-altitude {
    grid-area: stat-altitude;
}

.stat-altimeter {
    grid-area: stat-altimeter;
    align-self: start;
}

.stat {
    text-align: center;
}

.stat-value {
    font-size: 3vmax;
    line-height: 4vmax;
}

.stat-value-large {
    font-size: 8vmax;
    line-height: 8vmax;
}


.stat-label {
    font-size: 2vmax;
    line-height: 2vmax;
}


.home-camera-tile {
    grid-area: home-camera-tile;
    overflow: hidden;
    object-fit: cover;
    border: 2px solid #ccc;
    border-radius: 5px;
}

.home-camera-image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.home-camera-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 767px) {
    .home-weather-tile-temps {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3,1fr);
        grid-template-areas:
            "stat-temperature stat-temperature" 
            "stat-windchill stat-windchill"
            "stat-temp-min stat-temp-max";
    }

    .clocks-tile {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            "clock-station"
            "clock-utc";
    }

    #clock-utc .clock-tile-title, #clock-utc .plot-wrapper {
        display: none;
    }
}

    
