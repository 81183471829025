.camera-page {
    padding: 30px 30px;
    height: 100%;
    overflow-y: auto;

    display: grid;
    grid-gap: 1rem;
}

.camera-view {
    grid-area: camera-view;
    overflow-y: hidden; 
}

.camera-view-image-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
}

.camera-view-image-ratio {
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    z-image: 100;
}

.cover { 
    object-fit: cover;
}

.contain {
    object-fit: contain;
}

.image-overlay {
    position: absolute;
    font-size: 5vmin;
    color: darkblue;
    font-weight: bold;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.camera-card-image-wrapper .image-overlay {
    font-size: 2vmin;
}

.camera-card.active {
    border: 1px solid cyan; 
}

.camera-card-title.active {
    color: cyan; 
}

.camera-view-image {
    border: 1px solid #CCC; 
    object-position: 50% top;
    width: 100%;
    height: 100%;
    background: black; 
}

.camera-cards {
    grid-area: camera-cards;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px,1fr));
    grid-gap: 1rem;
}

.camera-card { 
    border: 1px solid #CCC;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr; 
    cursor: pointer;
}

.camera-card-title {
    color: #ccc;
    text-align: center;
    background-color: black; 
    font-size: 2vmin; 
}

.camera-card-image-wrapper {    
    /* Fix needed because Safari interprets the CSS spec for height differently 
     https://stackoverflow.com/questions/44770074/css-grid-row-height-safari-bug
     */

    position: relative;
}

.camera-card-image {   
    position: absolute;

   object-fit: cover;
    
    width: 100%;
    height: 100%;
}


@media (max-width: 767px) {
    div.camera-page {
        padding: 10px;
    }
}

@media (max-aspect-ratio: 4/3) {
    
    .camera-page {

        grid-template-columns: 1fr;
        grid-template-rows: 1fr 5fr;
        grid-template-areas:
            "camera-cards"
            "camera-view";
    } 

}

@media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 16/10) {

    .camera-page {
        grid-template-columns: 1fr 6fr;
        grid-template-areas:
            "camera-cards camera-view";
    } 

    .camera-cards {
        grid-template-columns: repeat(1, 1fr);
    }

} 

@media (min-aspect-ratio: 16/10) {

    .camera-page {
        grid-template-columns: 1fr 2fr;
        grid-template-areas:
            "camera-cards camera-view";
    } 

    .camera-cards {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
    }

    .camera-view-image {   
        object-position: 50% bottom;
    }
}

