.clock {
    fill: none;
    stroke: #999;
    stroke-width: 3px;
}

.clock-face-dial {
    stroke: white;
    stroke-width: 1px;
}

.clock-second-hand {
    stroke-width: 5;
    stroke: red;
}

.clock-minute-hand {
    stroke-width: 8;
    stroke-linecap: round;
    stroke: #c7c7c7;
}

.clock-hour-hand {
    stroke-width: 12;
    stroke-linecap: round;
    stroke: #c7c7c7;
}

.clock-hands-cover {
    stroke-width: 3;
    fill: #333;
}

.clock-second-tick {
    stroke-width: 3px;
    fill: #FFF;
}

.clock-second-label {
    font-size: 25px;
    fill: #ccc;
    stroke-width: 0;
}

.clock-hour-tick {
    stroke-width: 8;
}

.clock-hour-label {
    font-size: 45px;
    fill: #CCC;
    stroke-width: 0;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
}

