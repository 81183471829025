@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
html {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  background-color: #152235; 
  color: #CCC;
  /*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
}

a {
  /* Remove the gray background color from active links in IE 10. */
  background-color: transparent;
}

a:active,
a:focus,
a:hover {
  /* Improve readability of focused elements when they are also in an
   * active/hover state. */
  outline: 0;
}

#root {
    height: 100%; 

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        'header'
        'main'
        'footer';
}

main {
    grid-area: main;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.25);
    z-index:5;
    overflow-y: auto;

    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
}



header {
    grid-area: header;

    display: grid;
    grid-template-columns: 1fr auto 1fr; 
    grid-template-areas:
        'logo-left title logo-right';

    color: #CCC;
    background: linear-gradient(to top, #07111d, #152235);
    
    border-bottom: 1px solid cyan; 
    text-shadow: 1px 1px 1px #000;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.5);

    padding: 10px 15px;
    z-index: 11;
}

header h1 {
    margin: 0;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
}

header .center {
    grid-area: title;
    text-align: center;
}

header .logo-left {
    grid-area: logo-left;
    place-self: center left;
    height: 150px;
    top: -10px;
    z-index: 100;
    position: absolute;
}

header .logo-right {
    grid-area: logo-right;
    place-self: center end;
    height: 80px;
}

@media (max-width: 767px) {
    header h1 { font-size: 1em; }
    header .logo-left  { height: 95px; top: -5px; } 
    header .logo-right { height: 45px; } 
}


@media (max-width: 200px) {
    header {
        grid-template-columns: 0px 1fr 0px;
    }
    header h1 { font-size: 1em; }
    header .logo-left  { display: none; }
    header .logo-right { display: none; }
}


footer {
    grid-area: footer;

    background-color: #ccc;
    color: #152235;
    font-size: smaller;
    text-align: center;
    padding: 0.25em;
    font-family: 'Roboto Condensed', sans-serif;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas:
        "footer-status footer-attribution footer-release"
}

.footer-status {
    grid-area: footer-status;
    text-align: left;
    padding-left: 1em;
    text-transform: capitalize;
}

.footer-attribution {
    grid-area: footer-attribution;
    text-align: center;
}

.footer-release {
    grid-area: footer-release;
    text-align: right;
    padding-right: 1em;
}

@media (max-width: 767px) {
    .footer-attribution {
        font-size: xx-small;
    }
}

.clock {
    fill: none;
    stroke: #999;
    stroke-width: 3px;
}

.clock-face-dial {
    stroke: white;
    stroke-width: 1px;
}

.clock-second-hand {
    stroke-width: 5;
    stroke: red;
}

.clock-minute-hand {
    stroke-width: 8;
    stroke-linecap: round;
    stroke: #c7c7c7;
}

.clock-hour-hand {
    stroke-width: 12;
    stroke-linecap: round;
    stroke: #c7c7c7;
}

.clock-hands-cover {
    stroke-width: 3;
    fill: #333;
}

.clock-second-tick {
    stroke-width: 3px;
    fill: #FFF;
}

.clock-second-label {
    font-size: 25px;
    fill: #ccc;
    stroke-width: 0;
}

.clock-hour-tick {
    stroke-width: 8;
}

.clock-hour-label {
    font-size: 45px;
    fill: #CCC;
    stroke-width: 0;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
}


.winddial {
    fill: none;
    stroke: #999;
    stroke-width: 3px;
}

.winddial-face {
    fill: rgb(126,169,255);
    stroke: rgb(200,135,180);
    stroke-width: 0;
}

.winddial-marker {
    //fill: rgb(217,237,255);
    fill: orangered; 
    stroke: rgb(173,189,204);
    stroke-width: 0px;
}

.winddial-label { 
    fill: #ccc;
    font-size: 150px;
}

.home-page {
    display: none;
    padding: 2em; 
    height: 100%;
    overflow-y: auto;

    display: grid;
    grid-gap: 20px 10px; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "home-camera-tile home-time-tile"
        "home-weather-tile home-weather-tile";

    align-content: start;

    /*font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
}

.home-tile {
    /*border: 1px solid blue;
    background: #394263; 
    border-radius: 5px;*/
}

.home-time-tile {
    padding: 1em 0 0.5em 0;

    grid-area: home-time-tile;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        "clocks-tile"
        "home-time-date";
}

.home-time-date {
    grid-area: home-time-date;
    text-align: center;
    padding-top: 1vmax;
    font-size: 1.75vmax;
}

.clocks-tile {
    grid-area: clocks-tile;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
        "clock-station clock-utc";
}

.clock-tile {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
}

.clock-tile-title, .clock-tile-time {
    text-align: center;
    font-size: 2vmax;
}

.clock-tile-title {
    font-size: 2vmax;
}

#clock-station {
    grid-area: clock-station;
}

#clock-utc {
    grid-area: clock-utc;
}

.home-weather-tile {
    grid-area: home-weather-tile;
    
    display: grid;
    grid-gap: 20px 20px; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: auto 1fr; 
    grid-template-areas:
        "home-weather-tile-title home-weather-tile-title home-weather-tile-title"
        "home-weather-tile-temps home-weather-tile-wind home-weather-tile-altitude";
}

.home-weather-tile-temps, 
.home-weather-tile-wind, 
.home-weather-tile-altitude {

    border: 1px solid #07111d;
    border-radius: 20px; 
    //background-color: #07111d;

}

.home-weather-tile-temps {
    grid-area: home-weather-tile-temps;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "stat-temperature stat-temperature stat-temperature"
        "stat-temp-min stat-temp-max stat-windchill";

    justify-items: center;
    align-items: center;
    font-size: 5vmax; 
}

.stat-temperature {
    grid-area: stat-temperature;
}

.stat-temp-min {
    grid-area: stat-temp-min;
}

.stat-temp-max {
    grid-area: stat-temp-max;
}

.stat-windchill {
    grid-area: stat-windchill;
}

.home-weather-tile-title {
    grid-area: home-weather-tile-title;
    justify-self: center;
    font-size: 3vmax; 
    display: none;
}

.home-weather-tile-wind {
    grid-area: home-weather-tile-wind;
    justify-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.home-weather-tile-altitude {
    grid-area: home-weather-tile-altitude;
    white-space: nowrap;
    justify-items: center;
    align-items: center;

    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "stat-elevation stat-pressure"
        "stat-altitude stat-altitude"
        "stat-altimeter stat-altimeter";
        
}

.stat-elevation {
    grid-area: stat-elevation;
    align-self: end;
}

.stat-pressure {
    grid-area: stat-pressure;
    align-self: end;
}

.stat-altitude {
    grid-area: stat-altitude;
}

.stat-altimeter {
    grid-area: stat-altimeter;
    align-self: start;
}

.stat {
    text-align: center;
}

.stat-value {
    font-size: 3vmax;
    line-height: 4vmax;
}

.stat-value-large {
    font-size: 8vmax;
    line-height: 8vmax;
}


.stat-label {
    font-size: 2vmax;
    line-height: 2vmax;
}


.home-camera-tile {
    grid-area: home-camera-tile;
    overflow: hidden;
    object-fit: cover;
    border: 2px solid #ccc;
    border-radius: 5px;
}

.home-camera-image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.home-camera-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 767px) {
    .home-weather-tile-temps {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3,1fr);
        grid-template-areas:
            "stat-temperature stat-temperature" 
            "stat-windchill stat-windchill"
            "stat-temp-min stat-temp-max";
    }

    .clocks-tile {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            "clock-station"
            "clock-utc";
    }

    #clock-utc .clock-tile-title, #clock-utc .plot-wrapper {
        display: none;
    }
}

    


.weather-page {
    height: 100%;
    padding: 2em 100px 1em 100px;
    overflow-y: auto;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
        "weather-header"
        "weather-cards"
        "weather-plots";
    grid-gap: 10px 10px;
}

.weather-header  {
    grid-area: weather-header;
    border-bottom: 2px solid slategrey;
    text-align: center; 
    margin: 0;
    border: 1px solid red;
}

.weather-cards {
    grid-area: weather-cards;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-auto-rows: auto;
    grid-gap: 20px;
    margin: 10px 0;
    color: white;
}

.north-wind { 
    background-color: red !important;
    fill: red;
}

.weather-card {
    line-height: 1;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #394263;
    border-radius: 5px;

    display: grid;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    grid-template-areas:
        "card-title card-title"
        "card-value card-stats";
}

.card-title {
    grid-area: card-title;
    font-size: 2vw; 
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-value {
    grid-area: card-value;
    font-size: 3vw; 
    height: 100%;
    vertical-align: top;
}

.card-stats {
    grid-area: card-stats;
    height: 100%;
    display: grid;
    line-height: 1;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto; 
    grid-column-gap: 0.5em; 
    grid-row-gap: 0.25em;
    align-items: start;
}   

.card-stats > div {
    font-size: 1.1vmax; 
    white-space: nowrap;
}

.card-stats-compass {
    grid-area: card-stats;
    font-size: 2vw; 
}   

.weather-plots {
    grid-area: weather-plots;
    overflow-y: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    grid-template-areas:
        "temperature-plot windrose-plot"
         "winddir-plot windrose-plot"
         "windspeed-plot windrose-plot"
}

.windrose-plot {
    grid-area: windrose-plot;
}

.windline-plot {
    grid-area: windline-plot;
}

.winddir-plot {
    grid-area: winddir-plot;
}

.plot-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.temperature-plot {
    grid-area: temperature-plot;
}

.windspeed-plot {
    grid-area: windspeed-plot;
}

.pressure-plot {
    grid-area: pressure-plot;
}

.axis-grid line {
    stroke: #777; 
    stroke-width: 1px; 
}

@media (max-width: 1024px) {
    div.weather-page {
        padding: 2em 50px 1em 50px;
    }
}

@media (max-width: 800px) { 
    div.weather-page {
        padding: 10px;
    }

    .weather-cards {
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: auto; 
        grid-gap: 10px;
    }
 
    .weather-card {
        grid-template-columns: 1fr auto;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "card-title card-value"
            "card-stats card-stats";
    }

    .card-stats {
        grid-template-columns: repeat(6,-webkit-min-content);
        grid-template-columns: repeat(6,min-content);
    }   
    
    .card-value {
        font-size: 5vmin; 
    }

    .card-title {
        font-size: 3.5vmin; 
    }

    .card-stats > div {
        font-size: 2vmin; 
    }

}

@media (min-aspect-ratio: 16/10) and (max-width: 800px) {
    .weather-page {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
            "weather-header weather-header"
            "weather-cards weather-plots";
    }
 
    .weather-cards {
        grid-template-columns: 1fr; 
        grid-template-rows: auto; 
        grid-gap: 10px;
    }
 
    .weather-card {
        grid-template-columns: 1fr auto;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "card-title card-value"
            "card-stats card-stats";
    }

}

.camera-page {
    padding: 30px 30px;
    height: 100%;
    overflow-y: auto;

    display: grid;
    grid-gap: 1rem;
}

.camera-view {
    grid-area: camera-view;
    overflow-y: hidden; 
}

.camera-view-image-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
}

.camera-view-image-ratio {
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    z-image: 100;
}

.cover { 
    object-fit: cover;
}

.contain {
    object-fit: contain;
}

.image-overlay {
    position: absolute;
    font-size: 5vmin;
    color: darkblue;
    font-weight: bold;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.camera-card-image-wrapper .image-overlay {
    font-size: 2vmin;
}

.camera-card.active {
    border: 1px solid cyan; 
}

.camera-card-title.active {
    color: cyan; 
}

.camera-view-image {
    border: 1px solid #CCC; 
    object-position: 50% top;
    width: 100%;
    height: 100%;
    background: black; 
}

.camera-cards {
    grid-area: camera-cards;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px,1fr));
    grid-gap: 1rem;
}

.camera-card { 
    border: 1px solid #CCC;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr; 
    cursor: pointer;
}

.camera-card-title {
    color: #ccc;
    text-align: center;
    background-color: black; 
    font-size: 2vmin; 
}

.camera-card-image-wrapper {    
    /* Fix needed because Safari interprets the CSS spec for height differently 
     https://stackoverflow.com/questions/44770074/css-grid-row-height-safari-bug
     */

    position: relative;
}

.camera-card-image {   
    position: absolute;

   object-fit: cover;
    
    width: 100%;
    height: 100%;
}


@media (max-width: 767px) {
    div.camera-page {
        padding: 10px;
    }
}

@media (max-aspect-ratio: 4/3) {
    
    .camera-page {

        grid-template-columns: 1fr;
        grid-template-rows: 1fr 5fr;
        grid-template-areas:
            "camera-cards"
            "camera-view";
    } 

}

@media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 16/10) {

    .camera-page {
        grid-template-columns: 1fr 6fr;
        grid-template-areas:
            "camera-cards camera-view";
    } 

    .camera-cards {
        grid-template-columns: repeat(1, 1fr);
    }

} 

@media (min-aspect-ratio: 16/10) {

    .camera-page {
        grid-template-columns: 1fr 2fr;
        grid-template-areas:
            "camera-cards camera-view";
    } 

    .camera-cards {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
    }

    .camera-view-image {   
        object-position: 50% bottom;
    }
}



.map-page {
    padding: 2em 100px 1em 100px;
    height: 100%;
    overflow-y: auto;

    display: grid;
    grid-gap: 10px 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "map-site-photo"
        "map-site-plans";

}

.map-site-plans {
    grid-area: map-site-plans;
    overflow-y: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "map-site-overview map-site-plan";

    grid-gap: 10px 10px;
    align-content: start;
}

.map-image {
    width: 100%;
    height: 100%; 
    object-fit: contain; 
}

.map-image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.map-site-photo, .map-site-overview, .map-site-plan {
    grid-area: map-site-photo;
    overflow-y: hidden;
}

.map-site-plan {
    grid-area: map-site-plan;
}

.map-site-overview {
    grid-area: map-site-overview;
}

.map-image-ratio {
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    z-image: 100;
}

@media (max-width: 1024px) {
    .map-page {
        padding: 2em 50px 1em 50px;
    }
}

@media (max-width: 800px) {
    .map-page {
        padding: 10px;
    }
}

.about-page {
    padding: 2em 5em;
}

.about-page > .info {
    margin-bottom: 2em;
    font-size: larger;
}

.about-sites {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 1fr;
    grid-gap: 5em;
}

.about-site {
}

.about-site img {
    border: 1px solid white; 
    height: 100%;
    width: 100%;
    background-color: #ccc;
    position: relative;
    object-fit: contain;
}

